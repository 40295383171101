/* Header Styles */
.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header Styles */
.header {
    height: 90vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    position: relative;
    flex-direction: column;
}

.header-content {
    width: 28%;
    text-align: center;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
}

.header-content h1 {
    font-size: 3rem;
    font-family: 'Gilroy-Medium';
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0;
    font-weight: 400;
}

.hero-bottom-content {
    display: flex;
    justify-content: space-between;
    font-family: 'Gilroy-Medium';
}

.hero-bottom-left,
.hero-bottom-right {
    padding: 0 1rem;
    text-align: left;
    width: 25%;
}

.hero-bottom-content h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.hero-bottom-content p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
}

.hero-gradient-text {
    background: linear-gradient(90deg, #CE9FFC, #7367F0);
    -webkit-background-clip: text;
    color: transparent;
}

.special-i {
    font-family: 'Gilroy-Medium-Italic';
    letter-spacing: 0.12rem;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

/* Navbar will always be above header */
.header-container {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .header-content {
        width: 40%;
    }
}

/* Mobile and Tablet View */
@media (max-width: 768px) {
    .header {
        width: 100%;
    }

    .header-content {
        width: 50%;
        margin-top: 2rem;
    }

    .header-content h1 {
        font-size: 2.2rem;
    }

    .hero-bottom-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 2rem;
    }

    .hero-bottom-left,
    .hero-bottom-right {
        width: 100%;
        text-align: center;
    }

    .hero-bottom-content p {
        font-size: 1rem;
    }

    .hero-gradient-text {
        background: linear-gradient(90deg, #CE9FFC, #7367F0);
        -webkit-background-clip: text;
        color: transparent;
    }
}