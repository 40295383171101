/* Projects Section */
.projects-section {
    background-color: #050505;
    color: white;
    padding: 2rem 12rem;
}

.projects-header {
    text-align: left;
    margin-bottom: 2rem;
    font-family: 'Gilroy-Semibold';
}

.projects-title {
    background: linear-gradient(90deg, #CE9FFC, #7367F0);
    -webkit-background-clip: text;
    color: transparent;
}

.projects-header h2 {
    font-size: 2.5rem;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

/* Hover Effects */
.project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.project-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.project-card h3 {
    margin: 0rem 0 1rem 0;
    font-size: 1rem;
    color: white;
    display: flex;
    justify-content: left;
    text-align: left;
    font-family: 'Gilroy-Medium';
}

.project-card p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.7rem;
    margin-bottom: 1rem;
    gap: 0.2rem;
    text-transform: uppercase;
    display: flex;
    justify-content: left;
    text-align: left;
    font-family: 'Gilroy-Light';
}

.project-card .underline {
    height: 2px;
    background: rgba(255, 255, 255, 0.2);
    margin: 0.5rem 0;
    transition: background-color 0.3s ease;
}

.project-card:hover .underline {
    background: linear-gradient(90deg, #CE9FFC, #7367F0);
}

/* Custom Cursor for Projects */
.custom-cursor {
    position: fixed;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    pointer-events: none;
    z-index: 9999;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.project-card:hover~.custom-cursor {
    opacity: 1;
}

.navlink {
    text-decoration: none;
}

/* Media Queries for Responsiveness */

/* Tablet screens (768px to 1024px) */
@media (max-width: 1024px) {
    .projects-section {
        padding: 2rem 6rem;
        /* Reduce padding */
    }

    .projects-header h2 {
        font-size: 2rem;
        /* Reduce header font size */
    }

    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
        /* Keep 2 columns for tablets */
    }

    .project-card h3 {
        font-size: 1rem;
        /* Adjust project name font size */
    }

    .project-card p {
        font-size: 0.8rem;
        /* Adjust project description font size */
    }
}

/* Mobile screens (up to 768px) */
@media (max-width: 768px) {
    .projects-section {
        padding: 2rem 3rem;
        /* Further reduce padding */
    }

    .projects-header h2 {
        font-size: 1.8rem;
        /* Adjust header font size */
    }

    .projects-grid {
        grid-template-columns: 1fr;
        /* Switch to single column layout */
    }

    .project-card h3 {
        font-size: 1rem;
        /* Adjust project name font size */
    }

    .project-card p {
        font-size: 0.9rem;
        /* Adjust project description font size */
    }

    .custom-cursor {
        width: 50px;
        height: 50px;
        /* Smaller cursor on mobile */
    }
}

/* Extra small mobile screens (up to 480px) */
@media (max-width: 480px) {
    .projects-section {
        padding: 1.5rem 2rem;
        /* Reduce padding further */
    }

    .projects-header h2 {
        font-size: 1.5rem;
        /* Reduce header font size */
    }

    .project-card h3 {
        font-size: 0.9rem;
        /* Reduce project name font size */
    }

    .project-card p {
        font-size: 0.8rem;
        /* Reduce project description font size */
    }

    .custom-cursor {
        width: 40px;
        height: 40px;
        /* Even smaller cursor on very small screens */
    }
}

.project-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}