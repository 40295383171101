/* Navbar Styles */
.navbar {
    position: absolute;
    font-family: 'Gilroy-Semibold';
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: transparent;
    transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.navbar.scrolled {
    background-color: rgba(5, 5, 5, 0.9);
    backdrop-filter: blur(10px);
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 10rem; */
    padding: 1rem 2rem;
    max-width: 900px;
    margin: 0 auto;
}

.navbar-logo {
    display: flex;
    align-items: center;
    height: 2rem;
}

.logo-image {
    width: 100%;
    height: 100%;
}

.nav-links {
    display: flex;
    gap: 3rem;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0.4rem 0.8rem;
    border-radius: 50px;
    text-transform: uppercase;
    backdrop-filter: blur(8px);
}

/* Styling for NavLink */
.nav-links a {
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.3s ease;
    font-size: 0.8rem;
    border-radius: 50px;
}

/* Hover effect */
.nav-links a:hover {
    background: linear-gradient(to right, #ce9ffc, #7367f0);
    color: #fff;
}

.nav-links a.active {
    color: #fff;
    /* Active link styling */
}

/* Mobile and Tablet View */
.menu-toggle {
    display: none;
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 20px;
}

.menu-icon,
.menu-icon::before,
.menu-icon::after {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    transition: all 0.3s ease;
}

.menu-icon {
    top: 50%;
    transform: translateY(-50%);
}

.menu-icon::before {
    top: -8px;
}

.menu-icon::after {
    top: 8px;
}

/* Toggle effect: Transform for Hamburger to Close (shaving effect) */
.menu-toggle.open .menu-icon {
    transform: translateY(-50%) rotate(45deg);
}

.menu-toggle.open .menu-icon::before {
    transform: translateY(0) rotate(90deg);
}

.menu-toggle.open .menu-icon::after {
    transform: translateY(0) rotate(90deg);
}

/* Navigation Links Styles */
.nav-links {
    display: flex;
    gap: 3rem;
}

.nav-links.open {
    display: block;
}

/* 
@media (max-width: 768px) {
    .navbar {
        position: relative;
    }

    .navbar-content {
        flex-direction: row;
        align-items: flex-start;
    }

    .menu-toggle {
        display: block;
    }

    .nav-links {
        display: none;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 1rem 0;
        position: absolute;
        top: 60px;
        left: 0;
        transition: transform 0.3s ease-in-out;
    }

    .nav-links.open {
        display: block;
    }

    .nav-links a {
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
        width: 100%;
        display: block;
    }

    .nav-links a:hover {
        background: linear-gradient(to right, #ce9ffc, #7367f0);
        color: #fff;
        width: 95%;
    }

    .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 2rem;
        color: white;
        cursor: pointer;
        z-index: 1100;
    }
} */

/* Mobile and Tablet View */
@media (max-width: 768px) {
    .navbar {
        position: relative;
    }

    .navbar-content {
        flex-direction: row;
        align-items: flex-start;
    }

    .menu-toggle {
        display: block;
    }

    .nav-links {
        display: none;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 1rem 0;
        position: absolute;
        top: 60px;
        left: 0;
        transition: transform 0.3s ease-in-out;
    }

    .nav-links.open {
        display: block;
    }

    .nav-links a {
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
        width: 100%;
        display: block;
    }

    .nav-links a:hover {
        background: linear-gradient(to right, #ce9ffc, #7367f0);
        color: #fff;
        width: 95%;
    }

    /* Decrease size of logo in mobile view */
    .navbar-logo .logo-image {
        width: 50%;
        /* Adjust this value as needed */
        height: auto;
    }

    /* Close button styles */
    .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 2rem;
        color: white;
        cursor: pointer;
        z-index: 1100;
    }
}